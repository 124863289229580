<script>
import api from '@/command/api'
import CardIconLabel from '../../../components/CardIconLabel'
export default {
  name: 'labelManagement',
  data() {
    return {
      ...api.command.getState(),
      // 标签管理数据
      tagData: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      api.freight.getTypeList.call(this, { url: '/base/farmLabelType/list' })
    },
    sendData({ data, setHidden }, item) {
      api.freight.addType
        .call(this, {
          url: '/base/farmLabelData',
          params: {
            ...item,
            ...data,
            labelTypeId: item.id
          }
        })
        .then(setHidden)
    },
    onEdit({ data, setHidden }, item) {
      api.freight.editType
        .call(this, {
          url: '/base/farmLabelData',
          params: {
            ...item,
            ...data,
            labelTypeId: item.id
          }
        })
        .then(setHidden)
    },
    onDeleteData(data) {
      api.freight.deleteType.call(this, {
        url: `/base/farmLabelData/${data.id}`
      })
    }
  },
  render() {
    return (
      <div class="card-box">
        {this.tagData.map(item => {
          const code = [
            'farmhouse_bathroom',
            'farmhouse_food_safety',
            'farmhouse_room',
            'farmhouse_public',
            'fram_adopt_legal',
            'fram_adopt_grow',
            'fram_adopt_other'
          ]
          return (
            <CardIconLabel
              data={null}
              styles={{ marginBottom: '10px' }}
              title={item.labelTypeName}
              icons={item.farmLabelDataVOS.map(e => ({
                ...e,
                name: e.labelName
              }))}
              showIcon={code.indexOf(item.labelTypeCode) !== -1}
              //   showIcon={item.iconState == '1' ? true : false}
              onSuccess={data => this.sendData(data, item)}
              onEdit={data => this.onEdit(data, item)}
              onDelete={this.onDeleteData}
              labelTypeCode={item.labelTypeCode}
            />
          )
        })}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.card-box {
  height: calc(100vh - 108px);
  overflow-y: auto;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
